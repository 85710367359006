<template>
  <div>
    <div>
      {{ moment(cell.value).format('YYYY-MM-DD') }}
    </div>
    <div>
      {{ moment(cell.value).format('HH:mm') }}
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    cell: Object,
  },
  data () {
    return {
      moment,
    }
  },
}
</script>