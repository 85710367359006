<template>
  <div>
    <TheTopBar
      ref="topBarRef"
      @toggleShowSettings="showSettingsBar = true"
      class="top-bar"
    />
    <div :style="{ marginTop: `${topBarHeight}px` }">
      <SearchTermBar
        v-if="onTool"
      />
      <div class="page-content"   :style="{ height: `calc(100vh - ${topBarHeight}px)` }">
        <div
          v-if="onTool && large"
          :class="['sidebar', { 'mini-state': miniState }]"
        >
          <div class="sidebar-top-section">
            <template v-if="!miniState">
              <div class="flex-row-start">
                <div class="selected-text">
                0 Filter categories selected
                </div>
                <div class="clear-text">
                Clear all
                </div>
                <BaseIcon
                  v-if="isSidebarFiltersModified"
                  class="return-icon"
                  name="search"
                  @click="clickSearch('search')"
                />
              </div>
            </template>
            <MiniButtonContainer  @click="miniState = !miniState">
              <BaseIcon name="menu" />
            </MiniButtonContainer>
          </div>
          <div class="sidebar-bottom-border"/>
          <div v-if="!miniState" class="scrollable">
            <router-view name="settings" />
          </div>
        </div>
        <ThePage />
      </div>
    </div>
    <BaseModal v-if="onTool && !large" v-model="showSettingsBar">
      <TheSettingsBar />
    </BaseModal>
  </div>
</template>

<script>
import TheTopBar from '@/components/TheTopBar'
import TheSettingsBar from '@/components/TheSettingsBar'
import MiniButtonContainer from '@/components/input/MiniButtonContainer.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import BaseModal from '@/components/BaseModal'
import ThePage from '@/components/ThePage'
import SearchTermBar from './components/SearchTermBar.vue'
import { isTool } from '@/helpers/searchQuery'
import { createNamespacedHelpers, useStore } from 'vuex'
import { useTitle, useResizeObserver } from '@vueuse/core'
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

const { mapActions: mapSettingsActions, mapGetters } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    TheTopBar,
    TheSettingsBar,
    BaseIcon,
    MiniButtonContainer,
    ThePage,
    SearchTermBar,
    BaseModal,
    // IconTool
  },
  setup () {
    const route = useRoute()
    const store = useStore()
    const { t } = useI18n()

    const showSettingsBar = ref(true)
    const large = ref(false)

    store.subscribeAction((action) => {
      if (action.type === 'searchSettings/clickSearch' && !large.value) {
        // TODO: removing this seems to have no effect, it still works?
        showSettingsBar.value = false
      }
    })

    const onTool = computed(() => isTool(route.name))

    const title = computed(() => {
      const name = t(`nav.${route.name}`)
      return `${name} | Open Measures`
    })
    useTitle(title)

    const topBarRef = ref(null)
    const topBarHeight = ref(0)
    useResizeObserver(topBarRef, (entries) => {
      const entry = entries[0]
      topBarHeight.value = entry.borderBoxSize[0].blockSize
    })

    return { showSettingsBar, large, onTool, topBarRef, topBarHeight }
  },
  data () {
    return {
      miniState: false
    }
  },
  methods: {
    ...mapSettingsActions(['onCreated', 'clickSearch']),
    updateLarge () {
      this.large = window.matchMedia('(min-width: 1000px)').matches
    }
  },
  created () {
    this.updateLarge()
    window.addEventListener('resize', this.updateLarge)

    this.onCreated(this.$store)
  },
  computed: {
    ...mapGetters(['isSidebarFiltersModified']),
  }
}
</script>

<style lang="scss">
@import "global";
</style>

<style scoped lang="scss">
a {
  text-decoration: none !important;
}

.top-bar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.page-content {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto 1fr;
}

.sidebar {
  max-height: 100%;
  height: calc(100vh - 8.5378rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: var(--color-tan-100);
  padding: var(--spacing-xxl) var(--spacing-xxl);
  border-right: var(--border-width-1) solid var(--color-light-border);
  text-align: left;
  color: var(--color-black);
  width: 366px;
}

.scrollable {
  overflow-y: auto;
  height: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
  margin: 0 calc(-1 * var(--spacing-xxl));
  padding: 0 var(--spacing-xxl)
}

.return-icon {
  cursor: pointer;
  margin-left: var(--spacing-xl);
}

.sidebar-bottom-border {
  margin-top: var(--spacing-xxl);
  margin-left: calc(-1 * var(--spacing-4));
  margin-right: calc(-1 * var(--spacing-4));
  border-top: var(--border-width-2) solid var(--color-tan-300);
}

.sidebar.mini-state {
  width: 4.714rem;
  padding: var(--spacing-xxl)  var(--spacing-xxl) 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.sidebar-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected-text {
  font-size: var(--fs-2);
  line-height: var(--fs-5);
  font-weight: 400;
  color: var(--color-tan-600);
}

.clear-text {
  font-size: var(--fs-2);
  line-height: var(--fs-5);
  font-weight: 600;
  color: var(--color-tan-500);
  margin-left: var(--spacing-md);
  cursor: pointer;
}

.sidebar-title {
  background: var(--color-off-white);
  color: var(--color-black);
  font-size: 20px;
  font-weight: bold;
}

.mini-slot {
  background: var(--color-off-white);
}

.close-sidebar {
  top: 10px;
  right: 20px;

  &:hover {
    cursor: pointer;
  }
}
</style>
