<template>
  <div class="content-container">
    <Accordion title="Site" :badgeCount="activeSitesCount">
      <div style="margin-top: var(--spacing-xl)">
        <InputWebsites @update:activeCount="handleActiveCount" />
      </div>
    </Accordion>
  </div>
  <Border />
</template>

<script>
import Accordion from '../Accordion.vue'
import InputWebsites from '../input/InputWebsites.vue'
import Border from '../Border.vue'

export default {
  data () {
    return {
      activeSitesCount: 0
    }
  },
  components: {
    Accordion,
    InputWebsites,
    Border
  },
  methods: {
    handleActiveCount (count) {
      this.activeSitesCount = count
    }
  }
}
</script>

<style scoped>
.content-container {
  padding: var(--spacing-xxl) 0 var(--spacing-xxl);
}

.label {
  font-size: var(--fs-2);
  line-height: var(--fs-5);
  font-weight: 400;
  margin-left: var(--spacing-md);
  text-transform: capitalize;
}
</style>