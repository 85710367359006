<template>
  <div class="content-container">
    <Accordion title="Engagement" :badgeCount="activeEngagementCount">
      <div
        v-for="(filter, i) in filters"
        :key="filter.id"
        class="flex-row-start"
        style="margin-top: var(--spacing-xl)"
        @click="toggleEngagementSelection(i)"
      >
        <CheckBox
          :model-value="filter.selected"
          @update:modelValue="toggleEngagementSelection(i)"
          @click.stop
        />
        <div class="label">{{ filter.label }}</div>
      </div>
    </Accordion>
  </div>
  <Border />
</template>

<script>
import Accordion from '../Accordion.vue'
import CheckBox from '../input/CheckBox.vue'
import Border from '../Border.vue'

export default {
  data () {
    return {
      filters: [
        { label: '0 - 10', value: 10, selected: false },
        { label: '10 - 50', value: 50, selected: false },
        { label: '50 - 75', value: 75, selected: false },
        { label: '75 - 100', value: 100, selected: false },
        { label: '100+', value: 101, selected: false },
      ],
    }
  },
  components: {
    Accordion,
    CheckBox,
    Border
  },
  computed: {
    activeEngagementCount () {
      return this.filters.filter(type => type.selected).length
    }
  },
  methods: {
    toggleEngagementSelection (index) {
      this.filters[index].selected = !this.filters[index].selected
    }
  },
}
</script>

<style scoped>
.content-container {
  padding: var(--spacing-xxl) 0 var(--spacing-xxl);
}

.label {
  font-size: var(--fs-2);
  line-height: var(--fs-5);
  font-weight: 400;
  margin-left: var(--spacing-md);
  text-transform: capitalize;
}
</style>