<template>
  <div class="rules-bar">
    Rules go here
  </div>
</template>

<style scoped>
.rules-bar {
  width: 100%;
  height: 15rem;
  background: purple;
}
</style>