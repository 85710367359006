<template>
  <div>
    <template v-for="stat in types">
      <div v-if="cell.value[stat.field] !== undefined" :key="stat.field">
        {{ cell.value[stat.field] }} {{ stat.label }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    cell: Object,
  },
  data () {
    return {
      types: [
        {
          field: 'likes',
          label: 'Likes',
        },
        {
          field: 'dislikes',
          label: 'Dislikes',
        },
        {
          field: 'reshares',
          label: 'Reshares',
        },
        {
          field: 'replies',
          label: 'Replies',
        },
        {
          field: 'views',
          label: 'Views',
        },
        {
          field: 'friends',
          label: 'Friends',
        },
        {
          field: 'followers',
          label: 'Followers',
        },
        {
          field: 'following',
          label: 'Following',
        },
        {
          field: 'posts',
          label: 'Posts',
        },
      ],
    }
  }
}
</script>