<template>
  <div class="content-container">
    <Accordion title="Date Range">
      <div>
        <div
          class="buttons-container"
        >
          <Button
            v-for="range in dateRanges"
            :key="range.value"
            variant="tertiary"
            size="sm"
            style="padding: 4px"
            :isActive="selectedRange === range.value"
            @click="handleDateRangeSelection(range.value)"
          >
            {{ range.label }}
          </Button>
        </div>
         <div style="style" class="same-row">
          <InputStartDate v-model="computedStartDate" />
          <InputEndDate v-model="computedEndDate" />
        </div>
      </div>
    </Accordion>
  </div>
  <Border />
</template>

<script>
import moment from 'moment'
import { createNamespacedHelpers } from 'vuex'

import Accordion from '../Accordion.vue'
import Button from '../input/Button.vue'
import InputStartDate from '@/components/input/InputStartDate'
import InputEndDate from '@/components/input/InputEndDate'
import Border from '../Border.vue'

const { mapGetters, mapMutations } = createNamespacedHelpers('searchSettings')

export default {
  data () {
    return {
      dateRanges: [
        { label: 'Today', value: 'today' },
        { label: 'Yesterday', value: 'yesterday' },
        { label: 'Past 7 days', value: 'past7days' },
        { label: 'Past 30 days', value: 'past30days' },
        { label: 'Past 6 months', value: 'past6months' },
        { label: 'Past Year', value: 'pastyear' }
      ],
    }
  },
  components: {
    Accordion,
    Button,
    InputStartDate,
    InputEndDate,
    Border
  },
  computed: {
    ...mapGetters(['startDate', 'endDate']),
    computedStartDate: {
      get () {
        return this.startDate
      },
      set (value) {
        this.setStartDate(value) // Commit mutation when startDate changes
      }
    },
    computedEndDate: {
      get () {
        return this.endDate
      },
      set (value) {
        this.setEndDate(value) // Commit mutation when endDate changes
      }
    },
    selectedRange () {
      const today = moment().format('YYYY-MM-DD')
      const predefinedRanges = {
        today: { start: today, end: today },
        yesterday: {
          start: moment().subtract(1, 'day').format('YYYY-MM-DD'),
          end: today,
        },
        past7days: {
          start: moment().subtract(7, 'day').format('YYYY-MM-DD'),
          end: today,
        },
        past30days: {
          start: moment().subtract(30, 'day').format('YYYY-MM-DD'),
          end: today,
        },
        past6months: {
          start: moment().subtract(6, 'months').format('YYYY-MM-DD'),
          end: today,
        },
        pastyear: {
          start: moment().subtract(1, 'year').format('YYYY-MM-DD'),
          end: today,
        },
      }

      for (const [key, range] of Object.entries(predefinedRanges)) {
        if (this.startDate === range.start && this.endDate === range.end) {
          return key
        }
      }

      return 'custom'
    }
  },
  methods: {
    ...mapMutations(['setStartDate', 'setEndDate']), // Map Vuex mutations
    handleDateRangeSelection (value) {
      const today = moment().format('YYYY-MM-DD')
      let rangeStart

      switch (value) {
        case 'today':
          rangeStart = today
          break
        case 'yesterday':
          rangeStart = moment().subtract(1, 'day').format('YYYY-MM-DD')
          break
        case 'past7days':
          rangeStart = moment().subtract(7, 'day').format('YYYY-MM-DD')
          break
        case 'past30days':
          rangeStart = moment().subtract(30, 'day').format('YYYY-MM-DD')
          break
        case 'past6months':
          rangeStart = moment().subtract(6, 'months').format('YYYY-MM-DD')
          break
        case 'pastyear':
          rangeStart = moment().subtract(1, 'year').format('YYYY-MM-DD')
          break
        default:
          rangeStart = null
      }

      if (rangeStart && today) {
        this.setStartDate(rangeStart)
        this.setEndDate(today)
      }
    },
  }
}
</script>

<style scoped>
.content-container {
  padding: var(--spacing-xxl) 0 var(--spacing-xxl);
}

.buttons-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--spacing-md);
  margin-top: var(--spacing-xxl);
}

.same-row {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: var(--spacing-xxl);
  margin-top: var(--spacing-xl);
  padding-bottom: var(--spacing-md);
}
</style>