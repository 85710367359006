<template>
  <div class="search-control">
    <div class="left-part">
      <!--TODO: this toggle can have a shared base component with the one in TypeTabs.vue-->
      <ToggleButton
        :value="displayType"
        :options="displayOptions"
        @click="handleDisplayMode"
      />
      {{ numResults }} results
    </div>
    <div class="right-part">
      <LabelToggle
        :model-value="displayMedia"
        @update:model-value="$emit('update:displayMedia', $event)"
        label="Show Media"
      />
      <LabelToggle
        :model-value="blurThumbnails"
        @update:model-value="$emit('update:blurThumbnails', $event)"
        label="Blur thumbnails"
      />
      <!--TODO: there's another dropdown like this in LanguagePickerDropdown.vue-->
      <!--<q-select
        label="Sort by: Date"
        :model-value="sortBy"
        @update:model-value="$emit('update:sortBy', $event)"
        :options="['date', 'potatoes', 'apples']"
      />-->
      <ExportSelect @select="handleExportSelect" />
      <IconButton :variant="'secondary'" @click="$emit('share')">
        <img :src="shareIcon" alt="share icon" class="share-icon"/>
      </IconButton>
      <Button @click="$emit('summary')">
        See summary
      </Button>
    </div>
  </div>
</template>

<script>
import ToggleButton from '../input/ToggleButton.vue'
import LabelToggle from '../input/LabelToggle.vue'
import ExportSelect from '../input/ExportSelect.vue'
import IconButton from '../input/IconButton.vue'
import Button from '../input/Button.vue'

import shareIcon from '../../assets/share.svg'
import TableIcon from '../../assets/table.svg'
import GridIcon from '../../assets/grid.svg'
import PhotoGalleryIcon from '../../assets/photoGallery.svg'

export default {
  name: 'SearchControl',
  data () {
    return {
      displayOptions: [
        {
          value: 'table',
          src: TableIcon,
        },
        {
          value: 'cards',
          src: GridIcon,
        },
        {
          value: 'media',
          src: PhotoGalleryIcon,
        }
      ],
      shareIcon,
    }
  },
  components: {
    ToggleButton,
    LabelToggle,
    ExportSelect,
    IconButton,
    Button,
  },
  props: {
    displayType: String,
    numResults: Number,
    displayMedia: Boolean,
    blurThumbnails: Boolean,
    sortBy: String,
  },
  methods: {
    handleExportSelect (option) {
      this.$emit('doExport', option)
    },
    handleDisplayMode (mode) {
      this.$emit('update:displayType', mode)
    }
  }
}
</script>

<style scoped lang="scss">
.search-control {
  display: flex;
  gap: var(--spacing-1);
}

.left-part {
  display: flex;
  align-items: center;
  gap: var(--spacing-xxl);
}

.right-part {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: var(--spacing-xxl);
}

.share-icon {
  height: 16px;
  width: 16px
}
</style>