<template>
  <q-dialog
    :maximized="!large"
    square
    :model-value="modelValue"
    :persistent="persistent"
    :no-route-dismiss="noRouteDismiss"
    @update:model-value="$emit('input', $event), $emit('update:model-value', $event)"
  >
    <q-card class='base-modal-container' :style="{ 'max-width': maxWidth }">
      <div class="inside">
        <div class="top-bar">
          <div class="title">
            {{ title }}
          </div>
          <IconButton
            variant="secondary"
            @click="$emit('input', false), $emit('update:model-value', false)"
          >
            <BaseIcon name="close" size="16px"/>
          </IconButton>
        </div>
        <div class="slot">
          <slot></slot>
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import IconButton from './input/IconButton.vue'
import BaseIcon from './BaseIcon.vue'

export default {
  components: {
    IconButton,
    BaseIcon,
  },
  props: {
    modelValue: Boolean,
    maxWidth: String,
    persistent: { type: Boolean, default: false },
    noRouteDismiss: Boolean,
    title: String,
  },
  data () {
    return {
      large: false,
    }
  },
  methods: {
    updateLarge () {
      this.large = window.matchMedia('(min-width: 1000px)').matches
    }
  },
  created () {
    this.updateLarge()
    window.addEventListener('resize', this.updateLarge)
  }
}
</script>

<style>
.q-dialog__backdrop {
  background-color: var(--color-primary-950) !important;
  opacity: 0.8;
}
</style>

<style scoped lang="scss">
.base-modal-container {
  background-color: var(--color-off-white);
  border: var(--border-width-1) solid var(--color-tan-950);
  box-shadow: 8px 8px 0px 0px var(--color-primary-100);
}

.inside {
  background-color: var(--color-off-white);
  color: var(--color-black);
  height: 100%;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  font-family: var(--primary-font);
}

img.om-logo {
  height: 22px;
}

div.env {
  padding-left: 10px;
  color: red;
}


.top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: var(--spacing-xxl);
  margin-bottom: 0;
}

.title {
  color: var(--color-tan-950);
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.slot {
  max-width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
}

@media (max-width: 1000px) {

  .base-modal-container {
    width: 100vw;
    max-width: 100vw;
  }
}

@media (max-width: 650px) {
  .top-bar {
    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 24px;
    }
  }
}
</style>
