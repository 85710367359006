<template>
  <q-td :props="cell" :auto-width="cell.col.autoWidth">
    <DeleteCell v-if="cell.col.name === 'delete'" :cell="cell"/>
    <ActiveCell v-else-if="cell.col.name === 'media_active' && cell.value !== null" :cell="cell" active-type="media"/>
    <ActiveCell v-else-if="cell.col.name === 'active'" :cell="cell" active-type="crawl"/>
    <SourceCell
      v-else-if="cell.value && cell.col.name === 'source'"
      :value="cell.value"
    />
    <template v-else-if="cell.value && cell.col.name === 'last_fetched'">
      {{ cell.value.split("T")[0] }}
    </template>
    <ActivityCell
      v-else-if="cell.col.name === 'activity'"
      :cell="cell"
      :value="cell.value"
    />
    <LongCell
      v-else-if="cell.value && cell.col.name === 'id'"
      :value="cell.value"
      :max-length="10"
    />
    <LongCell
      v-else-if="cell.value && (cell.col.name === 'source_url' || cell.col.name === 'keyword')"
      :value="cell.value"
      :max-length="25"
    />
    <template v-else-if="cell.value">
      {{ cell.value }}
    </template>
  </q-td>
</template>

<script>
import DeleteCell from './DeleteCell.vue'
import ActiveCell from './ActiveCell.vue'
import SourceCell from '../SourceCell.vue'
import ActivityCell from './ActivityCell.vue'
import LongCell from './LongCell.vue'

export default {
  components: {
    DeleteCell,
    ActiveCell,
    SourceCell,
    ActivityCell,
    LongCell,
  },
  props: {
    cell: Object,
  },
}
</script>