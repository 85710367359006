<template>
  <div class="toolbar">
    <div class="left-part">
      <router-link :to="'/'" class="home-link">
        <img :src='omLogo' class='om-logo' />
      </router-link>
      <router-link to="/" class="hide-mobile">
        <ButtonInner>
          <span class='top-bar-link'> Home </span>
        </ButtonInner>
      </router-link>
      <a href='https://docs.openmeasures.io/partners' target='_blank' class="hide-mobile">
        <ButtonInner>
          <span class='top-bar-link'>{{ $t("nav.docs") }}</span>
        </ButtonInner>
      </a>
    </div>

    <InputSearchTerm v-if="isTool && hasScrolled" small class="hide-mobile"/>

    <div class="right-part">
      <q-btn
        v-if="isTool"
        class='edit-query'
        label='Edit Query'
        unelevated
        dense
        color="purple"
        @click="$emit('toggleShowSettings')"
        style='font-size: 10px; line-height: 13px; padding: 3px 10px;'
      />
      <div class="right-part hide-mobile">
        <ButtonOpenToolModal v-if="isTool" />
        <LanguagePickerDropdown />
        <ProfileButton />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonOpenToolModal from '@/components/input/tool-picker/ButtonOpenToolModal'
import ButtonInner from '@/components/input/ButtonInner'
import LanguagePickerDropdown from '@/components/input/LanguagePickerDropdown'
import InputSearchTerm from './../input/InputSearchTerm.vue'
import ProfileButton from './ProfileButton.vue'
import omLogo from '@/assets/om-logo-full.svg'
import { isTool } from '@/helpers/searchQuery'
import { computed, onMounted, ref } from 'vue'
const { HOME } = require('@/constants/tools')

export default {
  components: {
    ButtonOpenToolModal,
    ButtonInner,
    ProfileButton,
    LanguagePickerDropdown,
    InputSearchTerm,
  },
  setup () {
    const scrollY = ref(0)

    onMounted(() => {
      window.addEventListener('scroll', () => {
        scrollY.value = window.scrollY
      })
    })

    const hasScrolled = computed(() => {
      return scrollY.value > 70
    })


    return { hasScrolled }
  },
  data () {
    return {
      omLogo,
      HOME,
    }
  },
  computed: {
    isTool () {
      return isTool(this.$route.name)
    },
  },
}
</script>

<style scoped lang="scss">
.left-part {
  display: flex;
  align-items: center;
  gap: var(--spacing-xxxl);
  margin-right: auto;
}

.home-link {
  display: flex;
  align-items: center;
}

.toolbar {
  display: flex;
  background: var(--color-primary-950);
  border-bottom: 4px solid var(--color-primary-800);
  padding: var(--spacing-xxxl) var(--spacing-huge);
}

.om-logo {
  height: 24px;
}

.edit-query {
  display: none;
  margin-right: 2em;
  padding: 0 5px;
}

.right-part {
  display: flex;
  align-items: center;
  gap: var(--spacing-lg);
  margin-left: auto;
}

.top-bar-link {
  font-size: 12px;
  font-weight: bold;
}

@media (max-width: 1000px) {
  .edit-query {
    display: grid;
  }
}

@media (max-width: 650px) {
  .hide-mobile {
    display: none;
  }
}
</style>
