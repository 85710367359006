<template>
  <div class="content-container">
    <Accordion title="Media Format" badgeCount="4">
      <div style="margin-top: var(--spacing-xl)">
      </div>
    </Accordion>
  </div>
</template>

<script>
import Accordion from '../Accordion.vue'

export default {
  data () {
    return {
      types: [
        { label: 'post', value: false },
        { label: 'repost', value: false },
        { label: 'reply', value: false },
      ],
    }
  },
  components: {
    Accordion
  }
}
</script>

<style scoped>
.content-container {
  padding: var(--spacing-xxl) 0 var(--spacing-xxl);
}

.label {
  font-size: var(--fs-2);
  line-height: var(--fs-5);
  font-weight: 400;
  margin-left: var(--spacing-md);
  text-transform: capitalize;
}
</style>