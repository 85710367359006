<template>
  <div class="input-search-term">
    <template v-if="!small">
      <div style="display:flex; flex-direction: row">
        <q-btn-toggle
          no-caps
          spread
          toggle-color="our-blue"
          toggle-text-color="our-black"
          color="white"
          text-color="our-black"
          :model-value="esquery"
          @update:model-value="setEsQuery"
          :options="[
            {label: 'Basic', value: 'content'},
            {label: 'Boolean', value: 'boolean_content'},
            {label: 'Advanced', value: 'query_string'},
          ]"
        />
        <GhostButton mode="dark" @click="handleSeeDocs">
          See docs
        </GhostButton>
        <ViewHistory/>
      </div>
    </template>
    <div class="search-row">
      <SearchInput
        mode="dark"
        :placeholder="placeholderText"
        :model-value="searchTerm"
        @update:model-value="setSearchTerm"
        @enter="clickSearch($route.name)"
      />
      <IconButton @click="clickSearch($route.name)" variant="secondary">
        <BaseIcon name="search"/>
      </IconButton>
    </div>
  </div>
</template>
<script>
import IconButton from './IconButton.vue'
import BaseIcon from '@/components/BaseIcon'
import GhostButton from './GhostButton.vue'
import ViewHistory from './view-history/index.vue'

import { createNamespacedHelpers } from 'vuex'
import SearchInput from './SearchInput.vue'
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    SearchInput,
    IconButton,
    BaseIcon,
    GhostButton,
    ViewHistory,
  },
  props: {
    small: Boolean,
  },
  computed: {
    ...mapGetters(['searchTerm', 'esquery']),
    placeholderText () {
      switch (this.esquery) {
        case 'content':
          return 'Example: qanon'
        case 'boolean_content':
          return 'Example: qanon OR wwg1wga'
        case 'query_string':
          return 'Example: (author: anon123 AND text: qanon)'
        default:
          return ''
      }
    }
  },
  methods: {
    ...mapMutations(['setSearchTerm', 'setEsQuery']),
    ...mapActions(['clickSearch']),
    handleSeeDocs () {
      window.open('https://docs.openmeasures.io/docs/guides/public-app#search-term-options', '_blank')
    },
  },
}
</script>

<style scoped lang="scss">
.input-search-term {
  color: var(--color-white);
}

a {
  color: var(--color-light-grey);
}

p {
  text-align: left;
  font-size: var(--fs-3);
  font-weight: 600;
  margin-bottom: var(--spacing-2);
  font: var(--primary-font);
}

.search-row {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-1);
  margin-top: var(--spacing-3);
}
</style>
