<template>
   <DropdownWrapper
      :isOpen="isOpen"
      :mode="'dark'"
      :value="locale.toUpperCase()"
      :width="'6.214rem'"
      @toggle="toggleDropdown"
    >
      <div class="dropdown-list">
        <div
          v-for="lang in $i18n.availableLocales"
          :key="lang"
          clickable
          @click="locale = lang"
          class="dropdown-item"
        >
          {{ lang.toUpperCase() }}
        </div>
      </div>
  </DropdownWrapper>
</template>

<script>
import DropdownWrapper from './DropdownWrapper.vue'

export default {
  components: {
    DropdownWrapper,
  },
  data () {
    return {
      isOpen: false,
      language: this.$i18n.locale,
      languageOptions: this.$i18n.availableLocales.map(lang => ({
        label: lang.toUpperCase(),
        value: lang
      })),
    }
  },
  computed: {
    locale: {
      get () {
        return this.$i18n.locale
      },
      set (val) {
        // setting quasar's language as well to get e.g. localized calendars
        // https://quasar.dev/options/quasar-language-packs#Change-Quasar-Language-Pack-at-Runtime
        // switching en with en-us since quasar doesn't have plain en
        const qLang = val === 'en' ? 'en-US' : val
        import(
          `quasar/lang/${qLang}`
        )
          .then((lang) => {
            this.$q.lang.set(lang.default)
          })
          .catch((err) =>
            console.error("Couldn't import quasar language:", err)
          )

        this.$i18n.locale = val
        localStorage.setItem('locale', val)
        this.isOpen = false
      }
    },
  },
  created () {
    // try to be smart when picking the default language

    const browserLang = navigator.languages.find((lang) =>
      this.$i18n.availableLocales.includes(lang)
    )
    // default to what the user had last time, if anything
    // otherwise get a language that the browser says the user prefers
    // if we don't have a language like that, fall back to english
    this.locale = localStorage.getItem('locale') || browserLang || 'en'
  },
  methods: {
    toggleDropdown () {
      this.isOpen = !this.isOpen
    },
  }
}
</script>

<style scoped lang="scss">
.dropdown-list {
  position: absolute;
  width: 100%;
  border: var(--border-width-1) solid;
  margin-top: 4px;
  z-index: 1;
  border-color: var(--color-tan-100);
  background-color: var(--color-primary-950);
  box-shadow: var(--element-box-shadow) var(--color-primary-800);
}

.dropdown-item {
  padding: var(--element-padding-sm);
  cursor: pointer;
  color: var(--color-tan-400);
  transition: background-color 0.2s;
}

.dropdown-item:hover {
  color: var(--color-primary-950);
  background-color: var(--color-primary-100);
}
</style>
