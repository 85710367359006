<template>
  <div>
    <GhostButton mode="dark" @click="modalOpen = true">
      View History
    </GhostButton>
    <BaseModal
      v-model="modalOpen"
      max-width="800px"
      title="View Query History"
      no-route-dismiss
    >
      <div class="view-history-modal">
        <div class="description">
          Quickly access and cycle through your recent search queries
        </div>
        <SearchInput v-model="filterTerm" placeholder="Search" class="history-filter"/>
        <q-table
          flat
          row-key="id"
          dense
          table-header-class="view-history-header"
          card-style="background-color: unset"
          :filter="filterTerm"
          :pagination="{ rowsPerPage: 10 }"
          :visibleColumns="visibleColumns"
          :columns="columns"
          :rows="historyData"
        >
          <template v-slot:body-cell="cell">
            <HistoryCell
              :cell="cell"
            />
          </template>
        </q-table>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import GhostButton from '../GhostButton.vue'
import BaseModal from '../../BaseModal.vue'
import SearchInput from '../SearchInput.vue'
import HistoryCell from './HistoryCell.vue'

const {
  mapGetters,
} = createNamespacedHelpers('searchSettings')

export default {
  components: {
    GhostButton,
    BaseModal,
    SearchInput,
    HistoryCell,
  },
  data () {
    return {
      modalOpen: false,
      filterTerm: '',
    }
  },
  computed: {
    ...mapGetters(['searchHistory']),
    visibleColumns () {
      return this.columns.map(col => col.name)
    },
    columns () {
      return [
        {
          name: 'tool',
          label: 'Tool',
          autoWidth: true,
        },
        {
          name: 'searchTerm',
          label: 'Search Term',
        },
        {
          name: 'copy',
          label: '',
          autoWidth: true,
        },
        {
          name: 'totalResults',
          label: 'Total Results',
          autoWidth: true,
        },
        {
          name: 'sources',
          label: 'Sources',
          autoWidth: true,
        },
        {
          name: 'startDate',
          label: 'Start Date',
          autoWidth: true,
        },
        {
          name: 'endDate',
          label: 'End Date',
          autoWidth: true,
        },
        {
          name: 'menu',
          label: '',
          autoWidth: true,
        },
      ].map(col => ({
        field: col.name,
        align: 'left',
        ...col,
      }))
    },
    historyData () {
      return this.searchHistory.map(search => {
        const sources = search.searchSettings.websites.filter(site => site.active)

        return {
          tool: search.tool,
          searchTerm: search.searchSettings.searchTerm,
          copy: {
            ...search.searchSettings,
            websites: sources,
            tool: search.tool,
          },
          totalResults: search.totalResults,
          sources,
          startDate: search.searchSettings.startDate,
          endDate: search.searchSettings.endDate,
          menu: search.searchUrl,
        }
      })
    }
  },
}
</script>

<style>
.view-history-header {
  color: var(--color-tan-600);
  font-size: var(--fs-1);
  font-style: normal;
  font-weight: 500;
  line-height: var(--fs-4);
}

.view-history-modal td:first-child {
  padding-left: 8px;
}
</style>

<style scoped>
.view-history-modal {
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-bottom: var(--spacing-xxl);
  margin-top: 0;
}

.view-history-modal > * {
  margin-left: var(--spacing-xxl);
  margin-right: var(--spacing-xxl);
}

.description {
  color: var(--color-tan-950);

  font-family: Lexend;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.history-filter {
  margin-top: var(--spacing-xxl);
  margin-bottom: var(--spacing-xxl);
}
</style>