<template>
  <button
    @click="$emit('click')"
    v-ripple
    :disabled="disabled"
    :class="['mini-button', `mini-button-${mode}-mode`, { [`disabled-${mode}-mode`]: disabled }]"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'light',
      validator: (value) => ['light', 'dark'].includes(value),
    },
  },
  emits: ['click'],
}
</script>

<style scoped lang="scss">
.mini-button {
  position: relative;
  padding: var(--spacing-xs);
  cursor: pointer;
  border: 1px solid;
}

.mini-button-light-mode {
  background: var(--color-white);
  color: var(--color-tan-950);
  box-shadow: var(--element-box-shadow) var(--color-tan-300);
  border-color: var(--color-tan-950);
}

.mini-button-dark-mode {
  background: var(--color-primary-950);
  color: var(--color-tan-100);
  box-shadow: var(--element-box-shadow) var(--color-primary-800);
  border-color: var(--color-tan-100);
}

.mini-button-light-mode:not(.disabled-light-mode):hover {
  color: var(--color-primary-700);
  border-color: var(--color-primary-700)
}

.mini-button-dark-mode:not(.disabled-dark-mode):hover {
    color: var(--color-primary-100);
  border-color: var(--color-primary-100)
}

.disabled-light-mode {
  cursor: not-allowed;
  color: var(--color-white);
  background: var(--color-tan-400);
  box-shadow: none;
  border: none;
}

.disabled-dark-mode {
  cursor: not-allowed;
  color: var(--color-tan-600);
  background: var(--color-tan-900);
  box-shadow: none;
  border: none;
}
</style>
