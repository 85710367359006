<template>
  <q-card class="card">
    <slot/>
  </q-card>
</template>

<style scoped lang="scss">
.card {
  background: var(--color-white);
  border-radius: 0;
  border: 1px solid var(--color-tan-950);
  box-shadow: var(--element-box-shadow) var(--color-tan-300);
  overflow: hidden;
}
</style>