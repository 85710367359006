<template>
  <input
    type="checkbox"
    :model-value="modelValue"
    @input="$emit('update:modelValue', $event.target.checked)"
    :disabled="disabled"
    :class="['custom-checkbox', `${mode}-mode`,
      { [`disabled-${mode}-mode`]: disabled,
      'checked': modelValue
    }]"
  >
</template>

<script>
export default {
  props: {
    modelValue: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'light',
    },
  },
  emits: ['update:modelValue'],
}
</script>

<style scoped lang="scss">
.custom-checkbox {
  appearance: none; /* Removes default checkbox styling */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  width: var(--fs-1);
  height: var(--fs-1);
  border: 1px solid;
  cursor: pointer;
  position: relative;
  display: inline-block;
  transition: background-color 0.2s ease, border-color 0.2s ease;
}

/* Checkmark styling */
.custom-checkbox::after {
  content: '';
  position: absolute;
  width: 0.285rem; /* Thickness of the checkmark */
  height: 0.571rem; /* Length of the checkmark */
  border: solid var(--color-white); /* White checkmark */
  border-width: 0 1px 1px 0; /* Thickness of the checkmark lines */
  transform: rotate(45deg) scale(0); /* Hidden initially */
  transform-origin: bottom left;
  top: 15%;
  left: 25%;
  translate: -50% -50%;
  transition: transform 0.2s ease;
}

.light-mode {
  border-color: var(--color-tan-950);
}

.light-mode:not(.disabled-light-mode):hover {
  border-color: var(--color-primary-600);
}

/* When the checkbox is checked */
.light-mode.checked {
  background-color: var(--color-primary-600);
  border-color: var(--color-primary-600);
}

.light-mode.checked:hover {
  background-color: var(--color-primary-700);
  border-color: var(--color-primary-700);
}

/* When the checkbox is checked, show the checkmark */
.custom-checkbox.checked::after {
  transform: rotate(45deg) scale(1); /* Visible checkmark */
}

.disabled-light-mode {
  cursor: not-allowed;
  border-color: var(--color-tan-400);
}
</style>
