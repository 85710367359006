<template>
  <div>
    <DateRange />
    <Types />
    <Sites />
    <Engagement />
    <MediaFormat />
  </div>
</template>

<script>
import DateRange from './DateRange.vue'
import Types from './Types.vue'
import Sites from './Sites.vue'
import Engagement from './Engagement.vue'
import MediaFormat from './MediaFormat.vue'

export default {
  components: {
    DateRange,
    Types,
    Sites,
    Engagement,
    MediaFormat
  },
}
</script>