export function normaliseResults (website) {
  /**
  * Takes the raw result from the fetch and turns it into a nice format
  * that we can use
  */
  if (website === null) return []

  return website.map((websiteResult) => {
    const results = websiteResult.data.results.map(hit => hit._source)

    return results.map(result => {
      const media = result.openmeasures_media?.[0]

      return {
        created_at: result.created_at,
        type: result.datatype,
        text: result.text,
        author: result.actor?.username,
        context: result.context?.username,
        link: result.url,
        engagement: result.stats,
        mimetype: media?.mimetype,
        thumbnail_url: media?.openmeasures_thumbnail_url,
        media_url: media?.openmeasures_media_url,
        website: {
          name: websiteResult.name,
          label: websiteResult.label,
        },
      }
    })
  }).flat()
}