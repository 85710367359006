<template>
  <div class="menu-container">
    <q-btn push flat icon="more_vert">
      <q-popup-proxy ref="menuDropdown">
        <DropDown class="menu-dropdown">
          <div
            v-for="(option, index) in menuOptions"
            :key="index"
            :class="['dropdown-item']"
            @click="$refs.menuDropdown.hide()"
            >
              {{ option.label }}
            </div>
        </DropDown>
      </q-popup-proxy>
    </q-btn>
  </div>
</template>

<script>
import DropDown from '../input/DropDown.vue'

export default {
  components: {
    DropDown
  },
  data () {
    return {
      menuOptions: [
        { label: 'See Details' },
        { label: 'Show Record' },
        { label: 'View Media' },
        { label: 'Copy OM Link' },
        { label: 'Copy Original URL' }
      ]
    }
  },
}
</script>

<style scoped>
.menu-container {
  position: relative;
  display: inline-block;
}

.menu-dropdown {
  position: inherit;
  margin-left: -8.5rem;
  width: 11.285rem;
}

.dropdown-item {
  padding: var(--spacing-md) var(--spacing-xxl);
  cursor: pointer;
  transition: background-color 0.2s;
  color: var(--color-tan-600);
  font-size: var(--fs-2);
  font-weight: 400;
}

.dropdown-item:hover {
  color: var(--color-primary-950);
  background-color: var(--color-primary-100);
}
</style>