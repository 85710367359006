<template>
  <div class="search-cards">
    <ResultCard
      v-for="result in resultsData"
      :key="result"
      :result="result"
    />
  </div>
</template>

<script>
import ResultCard from './ResultCard.vue'

export default {
  components: {
    ResultCard,
  },
  props: {
    resultsData: Array,
  },
}
</script>

<style scoped>
.search-cards {
  display: flex;
  flex-direction: column;
  margin: var(--spacing-sm) 0;
  gap: var(--spacing-xxl);
}
</style>