<template>
  <div>
    <div class="search-term-bar">
      <div class="search-term-content">
        <InputSearchTerm/>
        <GhostButton mode="dark" @click="showRules = !showRules">
          {{ showRules ? 'Hide Rules' : 'Add Rule' }}
        </GhostButton>
      </div>
    </div>
    <RulesBar v-if="showRules"/>
  </div>
</template>

<script>
import InputSearchTerm from './input/InputSearchTerm.vue'
import GhostButton from './input/GhostButton.vue'
import RulesBar from './RulesBar.vue'

export default {
  components: {
    InputSearchTerm,
    GhostButton,
    RulesBar,
  },
  data () {
    return {
      showRules: false
    }
  },
}
</script>

<style scoped lang="scss">
.search-term-bar {
  background: url(~@/assets/pixely-bg.png) var(--color-primary-900);
  border-bottom: var(--border-width-1) solid var(--color-tan-500);
  padding: var(--spacing-huge) 0;
}

.search-term-content {
  margin: auto;
  max-width: 800px;
}
</style>