<template>
  <div class="sidebar-bottom-border" />
</template>


<style scoped>
.sidebar-bottom-border {
  margin-left: calc(-1 * var(--spacing-4));
  margin-right: calc(-1 * var(--spacing-4));
  border-top: var(--border-width-2) solid var(--color-tan-300);
}
</style>