<template>
  <q-td class="search-cell" :props="cell" :auto-width="cell.col.autoWidth">
    <DateCell v-if="cell.col.name === 'date'" :cell="cell"/>
    <TextCell v-else-if="cell.col.name === 'text' && cell.value" :cell="cell"/>
    <SourceCell
      v-else-if="cell.col.name === 'site'"
      :value="cell.value.name"
      :label="cell.value.label"
    />
    <EngagementCell v-else-if="cell.col.name === 'engagement' && cell.value" :cell="cell"/>
    <MediaCell
      v-else-if="cell.col.name === 'media'"
      :cell="cell"
      :blurThumbnails="blurThumbnails"
    />
    <MenuCell v-else-if="cell.col.name === 'menu'" :cell="cell"/>
    <template v-else-if="cell.value">
      {{ cell.value }}
    </template>
  </q-td>
</template>

<script>
import DateCell from '../DateCell.vue'
import TextCell from './TextCell.vue'
import EngagementCell from './EngagementCell.vue'
import MediaCell from './MediaCell.vue'
import MenuCell from './MenuCell.vue'
import SourceCell from '../SourceCell.vue'

export default {
  components: {
    DateCell,
    TextCell,
    EngagementCell,
    MediaCell,
    MenuCell,
    SourceCell,
  },
  props: {
    cell: Object,
    blurThumbnails: Boolean,
  },
}
</script>

<style scoped>
.search-cell {
  font-size: var(--fs-1);
}
</style>