<template>
  <SimpleCard>
    <div class="card-content">
      <div class="top-part">
        <div>
          {{ moment(result.date).format('MM-DD-YYYY • hh:mm a') }}
        </div>
        <ThreeDotMenuIcon />
      </div>
      <div class="middle-part">
        <div class="content-view">
          <div class="flex-row-center" style="  width: 1.571rem; height: 1.571rem">
            <PostIcon style="margin-right: var(--spacing-sm)" />
          </div>
          <div class="content-text">
            {{ result.text.slice(0, 1000) }}
          </div>
        </div>
        <img class="media" :src="result.media"/>
      </div>
      <div class="bottom-part">
        <div class="bottom-left">
           <div class="flex-row-start">
            <WebsiteIcon :website="result.site.name" style="margin-right: var(--spacing-xs)" />
            <div>
              {{ result.site.label }}
            </div>
           </div>
          <div>
            Channel
          </div>
          <div class="flex-row-start">
            <div class="icon flex-row-center">
              <UserIcon style="margin-right: var(--spacing-xs)" />
            </div>
            <div class="text-author">
              {{ result.author }}
            </div>
          </div>
        </div>
        <div class="bottom-right">
          <div class="flex-row-start">
            <div class="text-reaction">
              {{ result.engagement.reshares }}
            </div>
            <CommentIcon color="var(--color-primary-600)" style="margin-left: var(--spacing-xs)" />
          </div >
          <div class="flex-row-start">
            <div class="text-reaction">
              {{ result.engagement.likes }}
            </div>
            <LikeIcon color="var(--color-primary-600)" style="margin-left: var(--spacing-xs)" />
          </div>
          <div class="flex-row-start">
            <div class="text-reaction">
              {{ result.engagement.replies }}
            </div>
            <ReplyIcon color="var(--color-primary-600)" style="margin-left: var(--spacing-xs)" />
          </div>
        </div>
      </div>
    </div>
  </SimpleCard>
</template>

<script>
import moment from 'moment'
import SimpleCard from '../SimpleCard.vue'
import WebsiteIcon from '@/components/WebsiteIcon.vue'
import ThreeDotMenuIcon from '../../assets/threeeDotMenu.svg?component'
import PostIcon from '../../assets/svg/color/post.svg?component'
import UserIcon from '../../assets/svg/dark/user.svg?component'
import LikeIcon from '../../assets/svg/color/like.svg?component'
import CommentIcon from '../../assets/svg/color/comment.svg?component'
import ReplyIcon from '../../assets/svg/color/reply.svg?component'

export default {
  components: {
    SimpleCard,
    WebsiteIcon,
    ThreeDotMenuIcon,
    PostIcon,
    UserIcon,
    LikeIcon,
    CommentIcon,
    ReplyIcon
  },
  props: {
    result: Object,
  },
  data () {
    return {
      moment,
    }
  }
}
</script>

<style scoped>
.card-content {
  display: flex;
  flex-direction: column;
  margin: var(--spacing-huge) var(--spacing-xxl);
  gap: var(--spacing-xxl);
}

.top-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.middle-part {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr auto;
  align-items: center;
  gap: 50px;
}

.content-view {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.content-text {
  font-size: var(--fs-3);
  line-height: var(--fs-5);
  font-weight: 400;
  color: var(--color-tan-950);
}

.media {
  height: 150px;
}

.bottom-part {
  display: flex;
  justify-content: space-between;
}

.bottom-left {
  display: flex;
  gap: 27px;
}

.icon {
  width: 1.714rem;
  height: 1.714rem;
}

.text-author {
  font-size: var(--fs-2);
  line-height: var(--fs-5);
  font-weight: 500;
  color: var(--color-tan-950);
}

.text-reaction {
  font-size: var(--fs-2);
  line-height: var(--fs-5);
  font-weight: 400;
  color: var(--color-tan-950);
}

.bottom-right {
  display: flex;
  gap: var(--spacing-md);
}
</style>