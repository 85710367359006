<template>
  <div
    :style="style"
    :class="['container', `container-${mode}-mode`, { 'disabled-container': disabled, 'has-value': hasValue }]"
  >
    <BaseIcon
      name="search"
      size="16px"
      :style="{ color: disabled ? (mode === 'light' ? 'var(--color-tan-400)' : 'var(--color-tan-700)') : '' }"
      :class="[`icon-${mode}-mode`]"
    />
    <input
      :class="['input', `input-${mode}-mode`]"
      :placeholder="placeholder"
      :value="modelValue"
      @input="updateValue($event.target.value)"
      @keydown.enter.prevent="$emit('enter')"
      :disabled="disabled"
    />
    <BaseIcon
      v-if="hasValue"
      name="close"
      size="16px"
      @icon-clicked="handleIconClick"
      style="cursor: pointer"
      :style="{ cursor: 'pointer' }"
      :class="[`icon-${mode}-mode`]"
    />
  </div>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'

export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'light'
    },
    style: Object
  },
  emits: ['update:modelValue'],
  components: {
    BaseIcon
  },
  computed: {
    hasValue () {
      return this.modelValue.length > 0
    }
  },
  methods: {
    updateValue (value) {
      this.$emit('update:modelValue', value) // Emit to update the parent's value
    },
    handleIconClick () {
      this.$emit('update:modelValue', '') // Emit to empty the input
    },
  },
}
</script>

<style scoped lang="scss">
.container {
  padding: var(--element-padding-md);
  border: 1px solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  flex: 1;
}

.container-light-mode {
  border-color: var(--color-tan-950);
  background-color: var(--color-white);
  box-shadow: var(--element-box-shadow) var(--color-primary-100);
}

.container-dark-mode {
  border-color: var(--color-tan-300);
  background-color: var(--color-primary-950);
  box-shadow: var(--element-box-shadow) var(--color-primary-700);
}

.container-light-mode:hover {
  border-color: var(--color-primary-600);
}

.container-dark-mode:hover {
  border-color: var(--color-primary-300);
}

.container-light-mode:focus-within {
  border-color: var(--color-primary-600);
}

.container-dark-mode:focus-within {
  border-color: var(--color-primary-300);
}

.container-light-mode.disabled-container {
  border-color: var(--color-tan-400);
  box-shadow: none;
  cursor: not-allowed;
}

.container-dark-mode.disabled-container {
  border-color: var(--color-tan-700);
  box-shadow: none;
  cursor: not-allowed;
}

.container-light-mode.has-value {
  border-color: var(--color-primary-600);
}

.container-dark-mode.has-value {
  border-color: var(--color-primary-300);
}

.input {
  border: none;
  font-size: var(--fs-2);
  line-height: var(--fs-5);
  font-family: var(--primary-font);
  width: 100%;
  outline: none;
  margin-left: 8px;
}

.input-light-mode {
  background-color: var(--color-white);
  color: var(--color-tan-950);
}

.input-dark-mode {
  background-color: var(--color-primary-950);
  color: var(--color-tan-100);
}

.input-light-mode::placeholder {
  color: var(--color-tan-600);
}

.input-dark-mode::placeholder {
  color: var(--color-tan-400);
}

.input-light-mode:disabled::placeholder {
  color: var(--color-tan-400) !important;
}

.input-dark-mode:disabled::placeholder {
  color: var(--color-tan-700) !important;
}

.icon-dark-mode {
  color: var(--color-tan-300);
}
.icon-light-mode {
  color: var(--color-tan-800);
}

</style>
