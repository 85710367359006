<template>
  <div>
    <div class="site-row">
      <BaseCheckbox
        :model-value="allSelected"
        @update:model-value="selectAll"
      />
      Select all
    </div>
    <div v-for="site in sites" :key="site.name" class="site-row">
      <BaseCheckbox
        :model-value="site.active"
        @update:model-value="setSite({ siteName: site.name, active: $event })"
      />
      <WebsiteIcon :website="site.name" />
      {{ site.label }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import BaseCheckbox from '../BaseCheckbox.vue'
import WebsiteIcon from '../WebsiteIcon.vue'

export default {
  components: {
    BaseCheckbox,
    WebsiteIcon,
  },
  props: {
    sites: Array,
  },
  emits: [
    'update:sites',
  ],
  computed: {
    allSelected () {
      return this.sites.every(site => site.active)
    },
  },
  methods: {
    selectAll () {
      const active = !this.allSelected

      const sites = this.sites.map(site => ({
        ...site,
        active,
      }))

      this.$emit('update:sites', sites)
    },
    setSite ({ siteName, active }) {
      const sites = _.cloneDeep(this.sites)

      sites.find(s => s.name === siteName).active = active

      this.$emit('update:sites', sites)
    },
  },
}
</script>

<style scoped lang="scss">
.site-row {
  display: flex;
  align-items: center;
  gap: var(--spacing-1);
  font-family: var(--primary-font);
  padding: 0 var(--spacing-3);
}

.site-row:first-child {
  border-bottom: var(--border-width-1) var(--color-light-border) solid;
  padding-bottom: var(--spacing-3);
  margin-bottom: var(--spacing-3);
}
</style>