<template>
  <MiniButtonContainer
    :isOpen="isOpen"
    :mode="mode"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <BaseIcon :name="isOpen ? 'remove' :'add'" />
  </MiniButtonContainer>
</template>

<script>
import MiniButtonContainer from './MiniButtonContainer.vue'
import BaseIcon from '../BaseIcon.vue'

export default {
  props: {
    isOpen: Boolean,
    disabled: Boolean,
    mode: String
  },
  emits: ['click'],
  components: {
    MiniButtonContainer,
    BaseIcon
  },
}
</script>

<style scoped lang="scss">
.expand-button {
  position: relative;
  padding: var(--spacing-xs);
  cursor: pointer;
  border: 1px solid;
}

.expand-button-light-mode {
  background: var(--color-white);
  color: var(--color-tan-950);
  box-shadow: var(--element-box-shadow) var(--color-tan-300);
  border-color: var(--color-tan-950);
}

.expand-button-dark-mode {
  background: var(--color-primary-950);
  color: var(--color-tan-100);
  box-shadow: var(--element-box-shadow) var(--color-primary-800);
  border-color: var(--color-tan-100);
}

.expand-button-light-mode:not(.disabled-light-mode):hover {
  color: var(--color-primary-700);
  border-color: var(--color-primary-700)
}

.expand-button-dark-mode:not(.disabled-dark-mode):hover {
    color: var(--color-primary-100);
  border-color: var(--color-primary-100)
}

.disabled-light-mode {
  cursor: not-allowed;
  color: var(--color-white);
  background: var(--color-tan-400);
  box-shadow: none;
  border: none;
}

.disabled-dark-mode {
  cursor: not-allowed;
  color: var(--color-tan-600);
  background: var(--color-tan-900);
  box-shadow: none;
  border: none;
}
</style>
