<template>
  <div>
    <div
      v-if="cell.value.thumbnail_url"
      :class="['media-thumbnail', { blur: blurThumbnails }]"
      :style="{ 'background-image': `url(${cell.value.thumbnail_url})` }"
      @click="mediaModal = true"
    />
    <BaseModal v-model="mediaModal" max-width="unset">
      <div>
        <video
          v-if="cell.value.mimetype?.startsWith('video/')"
          :src="cell.value.media_url"
          controls
          class="media"
        />
        <img
          v-else-if="cell.value.mimetype?.startsWith('image/')"
          :src="cell.value.media_url"
          class="media"
        />
        <div v-else>
          Unsupported media mimetype {{ cell.value.mimetype }}
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from '../BaseModal.vue'

export default {
  components: {
    BaseModal,
  },
  props: {
    cell: Object,
    blurThumbnails: Boolean,
  },
  data () {
    return {
      mediaModal: false,
    }
  },
}
</script>

<style scoped>
.media-thumbnail {
  width: 80px;
  height: 48px;
  background-size: cover;
  cursor: pointer;
}

.blur {
  filter: blur(5px);
}

.media {
  max-height: 75vh;
  max-width: 75vw;
}
</style>