<template>
  <q-table
    flat
    bordered
    row-key="id"
    :dense="$q.screen.lt.md"
    :visibleColumns="visibleColumns"
    :columns="columns"
    :rows="resultsData"
    :loading="loading"
  >
    <template v-slot:body-cell="cell">
      <SearchCell
        :cell="cell"
        :blurThumbnails="blurThumbnails"
      />
    </template>
  </q-table>
</template>

<script>
import SearchCell from './SearchCell.vue'

export default {
  components: {
    SearchCell,
  },
  props: {
    resultsData: Array,
    loading: Boolean,
    displayMedia: Boolean,
    blurThumbnails: Boolean,
  },
  computed: {
    visibleColumns () {
      return ['date', 'type', 'text', 'site', 'channel', 'author', 'engagement', 'mediatype', 'media', 'menu']
        // remove media col if displayMedia is false
        .filter(col => !(col === 'media' && !this.displayMedia))
    },
    columns () {
      return [
        {
          name: 'date',
          label: 'Date & Time',
          autoWidth: true,
        },
        {
          name: 'type',
          label: 'Type',
          autoWidth: true, // this is not an actual col prop, but in SearchCell we grab this value and apply it there
        },
        {
          name: 'text',
          label: 'Text',
        },
        {
          name: 'site',
          label: 'Site',
          autoWidth: true,
        },
        {
          name: 'channel',
          label: 'Channel',
          autoWidth: true,
        },
        {
          name: 'author',
          label: 'Author',
          autoWidth: true,
        },
        {
          name: 'engagement',
          label: 'Engagement',
          autoWidth: true,
        },
        {
          name: 'mediatype',
          label: 'Media Type',
          autoWidth: true,
        },
        {
          name: 'media',
          label: 'Thumbnail',
          autoWidth: true,
        },
        {
          name: 'menu',
          label: '',
          autoWidth: true,
        },
      ].map(col => ({
        field: col.name,
        align: 'left',
        ...col,
      }))
    },
  },
}
</script>