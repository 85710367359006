<template>
  <div
    :style="style"
    :class="['badge', `${mode}-mode`, { [`disabled-${mode}-mode`]: disabled }]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'light',
      validator: (value) => ['light', 'dark'].includes(value),
    },
    style: Object,
  },
  emits: ['click'],
}
</script>

<style scoped lang="scss">
.badge {
  position: relative;
  font-size: var(--fs-1);
  font-weight: 500;
  line-height: var(--fs-5);
  width: 1.428rem;
  height: 1.428rem;
  text-align: center;
}

.light-mode {
  background: var(--color-white);
  color: var(--color-tan-800);
  border: none;
}

.dark-mode {
  background-color: var(--color-primary-900);
  color: var(--color-tan-300);
  border: none;
}

.disabled-light-mode {
  color: var(--color-tan-400);
}

.disabled-dark-mode {
  color: var(--color-tan-700);
}
</style>
