<template>
  <div class="content-container">
    <Accordion title="Type" :badgeCount="activeTypesCount">
      <div
        v-for="(type, i) in types"
        :key="type.id"
        class="flex-row-start"
        style="margin-top: var(--spacing-xl); cursor: pointer"
        @click="toggleTypeSelection(i, type)"
      >
        <CheckBox
          :model-value="type.active"
          @update:modelValue="toggleTypeSelection(i, type)"
          @click.stop
        />
        <div class="label">{{ type.label }}</div>
      </div>
    </Accordion>
  </div>
  <Border />
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import Accordion from '../Accordion.vue'
import CheckBox from '../input/CheckBox.vue'
import Border from '../Border.vue'

const { mapGetters, mapMutations } = createNamespacedHelpers('searchSettings')

export default {
  components: {
    Accordion,
    CheckBox,
    Border
  },
  computed: {
    ...mapGetters(['types']),
    activeTypesCount () {
      return this.types.filter(type => type.active).length
    }
  },
  methods: {
    ...mapMutations(['setTypesActive']),
    toggleTypeSelection (i, type) {
      const index = this.types.findIndex(t => t.name === type.name)
      this.setTypesActive({ i: index, val: !type.active })
    },
  },
}
</script>

<style scoped>
.content-container {
  padding: var(--spacing-xxl) 0 var(--spacing-xxl);
}

.label {
  font-size: var(--fs-2);
  line-height: var(--fs-5);
  font-weight: 400;
  margin-left: var(--spacing-md);
  text-transform: capitalize;
}
</style>